import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng4-click-outside';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import {  MatInputModule } from '@angular/material/input';
import {  MatSelectModule } from '@angular/material/select';

import {  MatSliderModule } from '@angular/material/slider';
import {  MatMenuModule } from '@angular/material/menu';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import {  MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ITableModule } from './components/table-filter/i-table.module';
import { ItrDatetimepickerModule } from './modules/itr-datetimepicker/itr-datetimepicker-table.module';

import { FilterPipe } from './pipes/filter.pipe';
import { StatusAndIdFilterPipe } from './pipes/status-and-id-filter.pipe';
import { RoleFilterPipe } from './pipes/role-filter.pipe';
import { ManagerFilterPipe } from './pipes/manager-filter.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { IdatePipe } from './pipes/idate.pipe';
import { ItimePipe } from './pipes/itime.pipe';
import { IdatetimePipe } from './pipes/idatetime.pipe';

import { OrientDirective } from './directives/orient.directive';
import { AddClassDirective } from './directives/addClass.directive';

import { FooterComponent } from './components/footer/footer.component';
import { ItrLoaderComponent } from './components/itr-loader/itr-loader.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ItrTableModule } from './modules/table';
import { RoleFormComponent } from './components/role-form/role-form.component';
import { SelectAutocompleteComponent } from './components/select-autocomplete/select-autocomplete.component';
import { ItrMultiSelectComponent } from './components/multi-select/multi-select.component';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { ItrSelectComponent } from './components/itr-select/itr-select.component';
import { ItrSelectSingleSearchComponent } from './components/itr-single-search/itr-select-single-search.component';
import { FieldsModule } from './components/fields/fields.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedServicesModule } from '@shared/services/shared-services.module';
import { AudioPlayerMinComponent } from './components/audio-player-min/audio-player-min.component';
import { StopwatchPipe } from './pipes/stopwatch.pipe';
import { ItrAudioPlayerComponent } from '@shared/components/itr-audio-player/itr-audio-player.component';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { ItrTableAgComponent } from './modules/table/components/itr-table-ag/itr-table-ag.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { AudiotypePipe } from './pipes/audiotype.pipe';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselSlideComponent } from './components/carousel/components/carousel-slide/carousel-slide.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { GalleryPreviewComponent } from './components/gallery-preview/gallery-preview.component';
import { StepListComponent } from './components/step-list/step-list.component';
import { ExternalFiltersComponent } from '@shared/modules/table/components/itr-table-ag/components/external-filters/external-filters.component';
import { CustomFilterComponent } from '@shared/modules/table/components/itr-table-ag/components/custom-filter/custom-filter.component';
import { UiModule } from "@itorum/ui";
import { VideoCallContainerComponent } from "@shared/components/video-call-container/video-call-container.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {
  IntentAddmemberComponent
} from "./components/video-call-container/components/intent-add-member/intent-add-member.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ClickOutsideModule,
    NgScrollbarModule,
    ItrTableModule,
    ITableModule,
    MatFormFieldModule,
    MatInputModule,
    ItrDatetimepickerModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    FieldsModule,
    BsDatepickerModule,
    SharedServicesModule,
    MatSliderModule,
    MatMenuModule,
    AgGridModule.withComponents([CustomFilterComponent]),
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    CarouselModule.forRoot(),
    UiModule,
    DragDropModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    FooterComponent,
    FilterPipe,
    StatusAndIdFilterPipe,
    RoleFilterPipe,
    ManagerFilterPipe,
    PhonePipe,
    SortPipe,
    ItrLoaderComponent,
    GalleryComponent,
    RoleFormComponent,
    IdatePipe,
    ItimePipe,
    IdatetimePipe,
    SelectAutocompleteComponent,
    ItrMultiSelectComponent,
    OrientDirective,
    AddClassDirective,
    SideModalComponent,
    ItrSelectComponent,
    ItrSelectSingleSearchComponent,
    AudioPlayerMinComponent,
    StopwatchPipe,
    ItrAudioPlayerComponent,
    LangSwitcherComponent,
    ItrTableAgComponent,
    AudiotypePipe,
    CarouselComponent,
    CarouselSlideComponent,
    GalleryPreviewComponent,
    StepListComponent,
    ExternalFiltersComponent,
    CustomFilterComponent,
    VideoCallContainerComponent,
    IntentAddmemberComponent
  ],
  exports: [
    ItrTableModule,
    ITableModule,
    FooterComponent,
    FilterPipe,
    StatusAndIdFilterPipe,
    RoleFilterPipe,
    ManagerFilterPipe,
    PhonePipe,
    SortPipe,
    ItrLoaderComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ClickOutsideModule,
    NgScrollbarModule,
    GalleryComponent,
    GalleryPreviewComponent,
    RoleFormComponent,
    IdatePipe,
    ItimePipe,
    IdatetimePipe,
    SelectAutocompleteComponent,
    ItrMultiSelectComponent,
    OrientDirective,
    AddClassDirective,
    SideModalComponent,
    MatFormFieldModule,
    MatInputModule,
    ItrDatetimepickerModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ItrSelectComponent,
    ItrSelectSingleSearchComponent,
    ItrAudioPlayerComponent,
    TranslateModule,
    FieldsModule,
    BsDatepickerModule,
    AudioPlayerMinComponent,
    StopwatchPipe,
    LangSwitcherComponent,
    ItrTableAgComponent,
    AudiotypePipe,
    CarouselComponent,
    CarouselSlideComponent,
    MatCheckboxModule,
    MatRadioModule,
    StepListComponent,
    MatDialogModule,
    CustomFilterComponent,
    VideoCallContainerComponent
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule {}
