import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DetachModeService {
  private isDetachModeEnabled = false;
  private instance: any;
  activateDetachMode() {
    this.isDetachModeEnabled = true;
  }

  deactivateDetachMode() {
    this.isDetachModeEnabled = false;
  }

  get getDetachModeStatus() {
    return this.isDetachModeEnabled;
  }

  saveInstance(instance: any) {
    this.instance = instance;
    console.log('saveInstance =>', this.instance);
  }

  getInstance() {
    return this.instance;
  }

  dropInstance() {
    this.instance = null;
  }
}
