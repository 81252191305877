import * as moment from 'moment';
import { fullNameToShort } from "../utils";

export interface IUserGlass {
  glass_id: number;
  glass_name: string;
}

export interface IUserRole {
  role_id: number;
  role_name?: string;
}

export interface IUserMinimalInfo {
  user_id: number;
  user_name?: string;
}

abstract class UserInitializer {
  login: string;
  auth_user_id: number;
  avatar_url: string;
  email: string;
  manager_names: string[];
  phone: string;
  phone_details?: {countryCode?: string, phone?: string};
  role_names: string[];
  status_name: string;
  id: number;
  user_id: number;
  user_name: string;
  user_position: string;

  first_name: string;
  last_name: string;
  middle_name: string;
  user_activation_date: string;
  last_session_date: string;
  status_start_date: string;
  competences: string;
  status: boolean; // online/offline ????
  glasses: Array<IUserGlass>;
  managers: IUserMinimalInfo[];
  roles: IUserRole[];
  experts: IUserMinimalInfo[];
  techniks: IUserMinimalInfo[];
  dates: {
    activation: Date;
    lastSession: Date;
    statusStart: Date;
  };
  organization_id?: number;
  org_name?: string;
  roles_id: number[];

  constructor(obj) {
    if (obj['login'] && typeof obj['login'] === 'string') {
      this.login = obj['login'];
    }
    if (obj['auth_user_id'] && typeof obj['auth_user_id'] === 'number') {
      this.auth_user_id = obj['auth_user_id'];
    }
    if (obj['avatar_url'] && typeof obj['avatar_url'] === 'string') {
      this.avatar_url = obj['avatar_url'];
    }
    if (obj['email'] && typeof obj['email'] === 'string') {
      this.email = obj['email'];
    }
    if (obj['user_position'] && typeof obj['user_position'] === 'string') {
      this.user_position = obj['user_position'];
    } else {
      this.user_position = '';
    }

    // Making array with empty space string element for filters on usersPage
    if (obj['manager_names'] && typeof obj['manager_names'] === 'string') {
      this.manager_names = obj['manager_names'].split(', ');
    } else {
      this.manager_names = ['-'];
    }

    if (obj['phone'] && typeof obj['phone'] === 'string') {
      this.phone = obj['phone'];
    } else {
      this.phone = '';
    }

    if (obj['phone_details'] && typeof obj['phone_details'] === 'object') {
      this.phone_details = obj['phone_details'];
    } else {
      this.phone_details = {};
    }

    if (obj['role_names'] && typeof obj['role_names'] === 'string') {
      this.role_names = obj['role_names'].split(', ');
    } else {
      this.role_names = ['-'];
    }

    if (obj['status_name'] && typeof obj['status_name'] === 'string') {
      this.status_name = obj['status_name'];
    }
    if (obj['user_id'] && typeof obj['user_id'] === 'number') {
      this.id = obj['user_id'];
    } else {
      this.id = null;
    }
    if (obj['user_id'] && typeof obj['user_id'] === 'number') {
      this.user_id = obj['user_id'];
    } else {
      this.user_id = null;
    }
    if (obj['user_name'] && typeof obj['user_name'] === 'string') {
      this.user_name = obj['user_name'];
    }
    if (obj['first_name'] && typeof obj['first_name'] === 'string') {
      this.first_name = obj['first_name'];
    }
    if (obj['last_name'] && typeof obj['last_name'] === 'string') {
      this.last_name = obj['last_name'];
    }
    if (obj['middle_name'] && typeof obj['middle_name'] === 'string') {
      this.middle_name = obj['middle_name'];
    } else {
      this.middle_name = '';
    }
    this.dates = <any>{};
    if (
      obj['user_activation_date'] &&
      typeof obj['user_activation_date'] === 'string'
    ) {
      this.user_activation_date = moment(obj['user_activation_date'])
        .locale('ru')
        .format('DD.MM.YYYY, h:mm:ss');
    }
    this.dates.activation = new Date(obj['user_activation_date']);
    if (
      obj['status_start_date'] &&
      typeof obj['status_start_date'] === 'string'
    ) {
      this.status_start_date = moment(obj['user_activation_date'])
        .locale('ru')
        .format('DD.MM.YYYY, h:mm:ss');
    }
    this.dates.statusStart = new Date(obj['status_start_date']);
    if (
      obj['last_session_date'] &&
      typeof obj['last_session_date'] === 'string'
    ) {
      this.last_session_date = moment(obj['last_session_date'])
        .locale('ru')
        .format('DD.MM.YYYY, h:mm:ss');
    }
    this.dates.lastSession = new Date(obj['last_session_date']);

    if (obj['competences'] && typeof obj['competences'] === 'string') {
      this.competences = obj['competences'];
    }
    if (obj['status'] && typeof obj['status'] === 'boolean') {
      this.status = obj['status'];
    }
    if (obj['glasses'] && typeof obj['glasses'] === 'object') {
      this.glasses = obj['glasses'];
    }
    if (obj['managers'] && typeof obj['managers'] === 'object') {
      this.managers = obj['managers'];
    }
    this.roles = Array.isArray(obj['roles']) ? obj['roles'] : [];
    this.roles_id = Array.isArray(obj['roles_id']) ? obj['roles_id'] : [];
    if (obj['experts'] && typeof obj['experts'] === 'object') {
      this.experts = obj['experts'];
    }
    if (obj['techniks'] && typeof obj['techniks'] === 'object') {
      this.techniks = obj['techniks'];
    }
    this.organization_id = obj.organization_id;
    this.org_name = obj.org_name;
  }
}

export interface IFullUserInfo {
  user_id?: number;
  login?: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  user_name?: string; // a rudiment
  phone?: string;
  phone_details?: {countryCode?: string, phone?: string}
  email?: string;
  status_name?: string;
  auth_user_id?: number;
  avatar_url?: string;
  competences?: string;
  experts?: any[];
  glasses?: any[];
  last_session_date?: string; // ISO date
  status_start_date?: string; // ISO date
  user_activation_date?: string; // ISO date
  managers?: IUserMinimalInfo[];
  roles?: IUserRole[];
  techniks?: IUserMinimalInfo[];
  user_position?: string;
  organization_id?: number;
  org_name?: string;
}

export class User extends UserInitializer {
  constructor(user: IFullUserInfo) {
    super(user);
  }

  public get shortName(): string {
    const fio = [this.last_name, this.first_name, this.middle_name]
      .join(' ')
      .trim();
    return fullNameToShort(fio ? fio : this.user_name);
  }

  public get shortFio(): string {
    const fio = [this.last_name, this.first_name].join(' ').trim();
    return fullNameToShort(fio ? fio : this.user_name);
  }

  get fullFio() {
    return `${this.last_name} ${this.first_name} ${this.middle_name}`;
  }

}

export interface IExpertData {
  expert_id?: number;
  expert_fio?: string;
  competences?: boolean | any;
}

export class Expert {
  expert_id: number;
  expert_fio: string;
  competences: boolean;

  constructor(data: IExpertData) {
    this.expert_id = (data.expert_id && +data.expert_id) || null;
    this.expert_fio = data.expert_fio && String(data.expert_fio);
    this.competences = !!data.competences;
  }

  public get shortName(): string {
    return fullNameToShort(this.expert_fio);
  }
}
