import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { RoomStateQuery } from "app/+conference/pages/room/state/room-state.query";
import { RoomStateService } from "app/+conference/pages/room/state/room-state.service";
import { JanusService } from "app/common/services/janus.service";
import { Router } from "@angular/router";
import { DetachModeService } from "app/common/services/detach-mode.service";
import { IControlChatMessageBody, IFeed } from "@itorum/models";
import { skip, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { IPublisher } from "app/+conference/pages/room/components/util";
import { BASE_PATH } from "@itorum/api";

@Component({
  selector: "itorum-video-call-container",
  templateUrl: "./video-call-container.component.html",
  styleUrls: ["./video-call-container.component.scss"]
})
export class VideoCallContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("video_tag") video_tag: ElementRef;
  @ViewChild("chat_input") chatInput: ElementRef;
  @ViewChild("publishers_container") publishersContainer: ElementRef<HTMLDivElement>;
  dragPosition = { x: 0, y: -350 };
  members_count = 0;
  messages_count = 0;
  display: "call" | "messages" | "members" = "call";
  isMenuExpanded = false;
  private destroy$ = new Subject();
  private publishers: IPublisher[];
  private feeds: IFeed[];
  private isAttached: boolean;
  dynamicRows = 1;
  empty = [];
  isShowVoiceRecordPanel = false;
  get isShowPullers(): boolean {
    if (this.feeds?.length < 2) {
      return false
    }

    let count = 0;
    this.feeds.forEach((feed) => {
      count += this.isFeedHasVideo(feed) ? 1 : 0;
    })

    if (count > 1) {
      return true;
    } else {
      return false;
    }
  }
  get link(): string {
    return `${this?.basePath}/account/invite/${this.janusService.uid}`
  }
  constructor(
    public roomStateQuery: RoomStateQuery,
    public roomStateService: RoomStateService,
    public janusService: JanusService,
    public detachModeService: DetachModeService,
    private router: Router,
    @Inject(BASE_PATH) private basePath: string
  ) {
  }

  ngOnInit(): void {
    this.roomStateQuery.getIsVideoAttached()
      .pipe(
        takeUntil(this.destroy$),
        skip(1)
      )
      .subscribe((isAttached) => {
        console.log("VideoCallContainerComponent roomStateQuery.getIsVideoAttached =>", isAttached);
        this.isAttached = isAttached;
        if (isAttached) {
          this.plugPublishers();
        } else {
          this.clearPublishers();
        }
      });

    this.roomStateQuery.getFeeds()
      .pipe(takeUntil(this.destroy$))
      .subscribe((feeds) => {
        console.log("VideoCallContainerComponent roomStateQuery.getFeeds =>", feeds);
        this.feeds = feeds;
      });


    this.janusService.publishers$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((publishers) => {
        if (!publishers) {
          console.log("VideoCallContainerComponent new_publisher_connected$ no publishers first time");
          return;
        }
        console.log("VideoCallContainerComponent init publishers first time =>", publishers);
        this.publishers = publishers;

      });

    this.janusService.new_publisher_connected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((publishers) => {
        console.log("VideoCallContainerComponent new_publisher_connected$ =>", publishers);
        if (!publishers) {
          console.log("VideoCallContainerComponent new_publisher_connected$ no publishers");
          return;
        }

        if (this.isAttached) {
          this.plugPublishers(publishers);
        }

        publishers.forEach((publisher) => {
          // remove duplicate before adding
          this.publishers.push(publisher);
        });


      });

    this.janusService.publisher_unpublished$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ quited: publisher, rfindex }) => {
        console.log("VideoCallContainerComponent publisher_unpublished$ ", publisher);
        if (!publisher) {
          return;
        }

        this.clear(publisher?.id);
      });

    this.janusService.someVideoStateChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe((feed: IFeed) => {
        console.log('VideoCallContainerComponent someVideoStateChanges$ feed =>', feed);

        if (feed.video.hasVideo) {
          this.janusService.videoAttach(feed); //some duplicates
        }

      })
  }

  ngAfterViewInit(): void {
    this.janusService.saveAttach(this.video_tag);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  returnToRoom() {
    // this.display = 'call';
    this.roomStateService.doVideoAttach(false);
    this.detachModeService.deactivateDetachMode();
    this.detachModeService.dropInstance();
    this.router.navigateByUrl(`main/conference/room/${this.janusService.currentRoom}`)
      .then((value) => {
        console.log("returnToRoom =>", value);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  trackByChatId(index: number, control: IControlChatMessageBody) {
    return control.timestamp;
  }

  openImage(url) {
    window.open(url, "_blank");
  }

  private addNewVideoTag(id: number): HTMLVideoElement {
    const videoTag: HTMLVideoElement = document.createElement("video");
    videoTag.style.cssText = "height: 0";
    videoTag.classList.add("att-publisher");
    videoTag.id = `att_publisher_${id}`;
    videoTag.setAttribute("autoplay", "");
    videoTag.setAttribute("playsinline", "");
    return this.publishersContainer.nativeElement.insertAdjacentElement("afterbegin", videoTag) as HTMLVideoElement;
  }

  private removeVideoTag(id: number) {
    if (!id) {
      console.error('removeVideoTag id =>', id);
      return;
    }
    const attr_id = `att_publisher_${id}`;
    console.log('removeVideoTag attr_id =>', attr_id);
    const tag = document.getElementById(attr_id);
    tag.parentNode.removeChild(tag);
  }

  clear(id?: number) {
    console.log('VideoCallContainerComponent id =>', id);
    if (id) {
      const index = this.publishers.findIndex((p) => p.id === id);
      const findex = this.feeds.findIndex((p) => p.rfid === id);
      console.log('VideoCallContainerComponent index =>', index);

      if (index !== -1) {
        this.removeVideoTag(id);
        delete this.publishers[index];
      }

      if (findex !== -1) {
        delete this.feeds[findex];
      }

    } else {
      console.log('publishers before clearing =>', this.publishers);
      this.publishers?.forEach((publisher) => {
        this.removeVideoTag(publisher.id);
      });
      this.publishers = [];
      this.feeds = [];
    }
  }

  private plugPublishers(publishers?: IPublisher[]) {
    console.log("VideoCallContainerComponent plugPublishers");
    let collection;

    if (publishers) {
      collection = publishers;
    } else {
      collection = this.publishers;
    }

    collection?.forEach((publisher) => {
      console.log("VideoCallContainerComponent id => ", publisher?.id);
      const tag = this.addNewVideoTag(publisher?.id);

      // const tag = this.videoTags[i].nativeElement;
      this.janusService.newremoteFeed(publisher.id, publisher.display, publisher.audio_codec, publisher.video_codec, tag);
    });

    // this.feeds.forEach((feed) => {
    //   const tag = this.addNewVideoTag(+feed?.id);
    //   console.log('VideoCallContainerComponent plugPublishers feed =>', feed);
    //   // this.janusAttachMediaToTag(tag, feed);
    //
    //
    // })
  }

  private clearPublishers() {
    console.log("VideoCallContainerComponent clearPublishers invoked");
    this.clear();
  }

  janusAttachMediaToTag(tag: HTMLVideoElement, feed) {
    this.janusService.attachMediaStreamToTag(feed, tag);
  }

  checkRows() {
    let len = this.chatInput?.nativeElement?.value?.length || 30;
    len = len + 15;
    this.dynamicRows = Math.ceil(len / 30);
  }

  sendChatMessage(text: string) {
    this.detachModeService?.getInstance()?.sendChatMessage(text);
  }

  startVoiceRecord() {
    this.detachModeService?.getInstance()?.startVoiceRecord();
  }

  cancelVoiceRecord() {
    this.detachModeService?.getInstance()?.cancelVoiceRecord();
  }

  submitVoiceRecord() {
    this.detachModeService?.getInstance()?.submitVoiceRecord();
  }

  clipRead() {
    navigator.clipboard.writeText(this.link);
  }

  isFeedHasVideo(feed: IFeed): boolean {
    return !!feed?.video?.hasVideo;
  }

  nextVideo() {
    const videoFeed = this.getVideoFeed();
    const nowVideoFeeds = this.getNowVideoFeeds();

    let nowIndex = this.getNowIndex(nowVideoFeeds, videoFeed);

    if (nowIndex === 0) {
      nowIndex = nowVideoFeeds.length - 1;
    } else {
      nowIndex--;
    }

    this.janusService.videoAttach(nowVideoFeeds[nowIndex]);
  }

  prevVideo() {
    const videoFeed = this.getVideoFeed();
    const nowVideoFeeds = this.getNowVideoFeeds();
    let nowIndex = this.getNowIndex(nowVideoFeeds, videoFeed);

    if (nowIndex === nowVideoFeeds.length - 1) {
      nowIndex = 0;
    } else {
      nowIndex++;
    }

    this.janusService.videoAttach(nowVideoFeeds[nowIndex]);
  }

  private getNowVideoFeeds(): IFeed[] {
    return this.feeds.filter((feed) => this.isFeedHasVideo(feed))
  }

  private getVideoFeed() {
    let videoFeed = this.janusService._nowInAttachSaveFeed;
    if(!videoFeed) {
      videoFeed = this.janusService._nowCenteredFeed;
    }
    return videoFeed;
  }

  private getNowIndex(nowVideoFeeds, videoFeed) {
    return nowVideoFeeds.findIndex((feed) => feed.rfid === videoFeed.rfid);
  }

  endCall() {
    this.roomStateService.doVideoAttach(false);
    this.detachModeService.deactivateDetachMode();
    this.detachModeService.getInstance().ngOnDestroy();
    this.detachModeService.dropInstance();
  }
}
